import { ApplicationConfig, importProvidersFrom, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { APP_ROUTES } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {tokenInterceptor} from "./core/interceptors/token.interceptor";
import {provideToastr, ToastrModule} from "ngx-toastr";
import {provideAnimations} from "@angular/platform-browser/animations";
import {blockUiInterceptor} from "./core/interceptors/block-ui.interceptor";
import {FlatpickrModule} from "angularx-flatpickr";
import { registerLocaleData } from "@angular/common";
import pt from '@angular/common/locales/pt'
import {provideEnvironmentNgxMask, provideNgxMask} from "ngx-mask";

registerLocaleData(pt)

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR'},
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(APP_ROUTES),
    provideAnimationsAsync(),
    provideAnimations(), // required animations providers
    provideToastr(), // Toastr providers
    provideEnvironmentNgxMask(),
    provideNgxMask(),
    provideHttpClient(
      withInterceptors([tokenInterceptor, blockUiInterceptor])
    ),
    importProvidersFrom(FlatpickrModule.forRoot())
  ]
};
