import { HttpInterceptorFn } from '@angular/common/http';
import {BlockUiService} from "../services/block-ui.service";
import {inject} from "@angular/core";
import {finalize} from "rxjs";

export const blockUiInterceptor: HttpInterceptorFn = (req, next) => {
  const blockUiService:BlockUiService = inject(BlockUiService)

  if (blockUiService.activateBlockUI() && ['POST', 'PUT', 'DELETE'].includes(req.method)) {
    blockUiService.loading.set(true);
  }

  return next(req).pipe(finalize(() => {
    if (['POST', 'PUT', 'DELETE'].includes(req.method)) {
      blockUiService.loading.set(false);
    }
  }));
};
