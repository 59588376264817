import {Route} from "@angular/router";
import {LoginComponent} from "./login/login.component";
import {authGuard} from "./core/guards/auth.guard";
import {inject} from "@angular/core";
import {AuthService} from "./core/services/auth.service";



export const APP_ROUTES: Route[] = [
  {
    path: 'login',
    component: LoginComponent,
    canMatch: [() => { return !inject(AuthService).logged() }],
  },
  {
    path: '',
    loadChildren: () => import('./layout/layout.routes').then(r => r.LAYOUT_ROUTES),
    canMatch: [ authGuard ],
  }
];
