import { Injectable } from '@angular/core';
import { CryptoKeyInterface, CryptoKeyServiceInstance } from "../instances/crypto-key.instance";
import * as CryptoJS from 'crypto-js';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  private _y: CryptoKeyInterface = CryptoKeyServiceInstance();

  constructor() { }

  d(obj_json: any): any {
    const x = this.getX();

    const encrypted = obj_json.ciphertext;

    const salt = CryptoJS.enc.Hex.parse(obj_json.salt);

    const iv = CryptoJS.enc.Hex.parse(obj_json.iv);

    const key = CryptoJS.PBKDF2(x, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999});

    const decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv});

    return JSON.parse(decrypted.toString(CryptoJS.enc.UTF8));
  }

  d3(obj_json: any): any {
    try {
      // Verificar se o objeto JSON contém todos os elementos necessários
      if (!obj_json.ciphertext || !obj_json.salt || !obj_json.iv) {
        throw new Error("Dados insuficientes para descriptografia.");
      }

      const x = this.getX(); // Obtém a chave secreta

      // Parse dos dados recebidos
      const encrypted = CryptoJS.enc.Base64.parse(obj_json.ciphertext); // Certificar-se de que está em Base64
      const salt = CryptoJS.enc.Hex.parse(obj_json.salt);
      const iv = CryptoJS.enc.Hex.parse(obj_json.iv);

      // Gerar a chave de descriptografia
      const key = CryptoJS.PBKDF2(x, salt, {
        hasher: CryptoJS.algo.SHA512,
        keySize: 64 / 8,
        iterations: 999
      });

      // Realizar a descriptografia
      const decrypted = CryptoJS.AES.decrypt({ ciphertext: encrypted }, key, { iv: iv });

      // Converter para string UTF-8
      const decryptedStr = decrypted.toString(CryptoJS.enc.Utf8);

      // Verificar se a string descriptografada é válida
      if (!decryptedStr) {
        throw new Error("Erro ao converter os dados descriptografados para UTF-8.");
      }

      return JSON.parse(decryptedStr);
    } catch (error) {
      console.error("Erro na descriptografia:", error.message);
      return null; // Ou outra forma de tratamento de erro
    }
  }



  e(value: any): { ciphertext: any; salt: any; iv: any } {
    const x = this.getX();

    const plaintext = JSON.stringify(value);

    const salt = CryptoJS.lib.WordArray.random(256);

    const iv = CryptoJS.lib.WordArray.random(16);

    const key = CryptoJS.PBKDF2(x, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999 });

    const encrypted = CryptoJS.AES.encrypt(plaintext, key, {iv: iv});

    return {
      ciphertext : CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
      salt : CryptoJS.enc.Hex.stringify(salt),
      iv : CryptoJS.enc.Hex.stringify(iv)
    }
  }

  getX(): string {
    let x = environment._s;

    this._y.get().subscribe(res => {
      if (res) x = res
    });

    return x;
  }
}
