import {Component, DestroyRef, effect, ElementRef, HostBinding, inject} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {FormsModule} from "@angular/forms";
import {DarkModeService} from "./core/services/dark-mode.service";
import {ButtonDarkModeComponent} from "./smart-components/button-dark-mode/button-dark-mode.component";
import {AuthService} from "./core/services/auth.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {LoadingComponent} from "./dumb-components/loading/loading.component";
import {BlockUiService} from "./core/services/block-ui.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, FaIconComponent, FormsModule, ButtonDarkModeComponent, LoadingComponent,],
  template: `
    <div class="{{ userData().theme }}">
      <router-outlet>
        <app-loading></app-loading>
      </router-outlet>
    </div>          `
})
export class AppComponent {
  protected blockUIService:BlockUiService = inject(BlockUiService)
  protected authService:AuthService = inject(AuthService)

  protected userData = this.authService.userData
  protected htmlEl: HTMLHtmlElement;

  constructor() {
    this.htmlEl = document.getElementsByTagName("html")[0];
    this.blockUIService.start()
    this.authService.isLogged().pipe(takeUntilDestroyed(inject(DestroyRef))).subscribe().add(() => this.blockUIService.stop());

    effect(() => {
      if (!this.userData().theme) return
      this.htmlEl.setAttribute('data-theme', this.userData().theme);
    })
  }
}
