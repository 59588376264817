import {CanActivateFn, Router} from '@angular/router';
import {tap} from "rxjs";
import {AuthService} from "../services/auth.service";
import {inject} from "@angular/core";

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const url = window.location.pathname;

  if (!authService.logged() && !url.includes('login')) {
    return router.navigate(['/login']);
  }
  return true
};
