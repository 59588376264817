<div class="h-screen bg-skin-bgColor flex items-center justify-center">
  <div class="card w-400px h-580px">
    <div class="flex flex-col w-full h-full">
      <div class="flex items-center justify-center" style="padding: 50px 0 30px 0">
        <img style="width: 135px!important;" src="/logo.svg" alt="logo">
      </div>
      <div class="flex flex-col flex-1 justify-center" [formGroup]="form">
        <div style="padding: 10px 30px">
          <span class="labelMajor">Bem vindo!</span>
        </div>
        <div style="padding: 10px 30px">
          <span class="label">Usuário:</span>
          <input class="input-line-bottom" type="text" formControlName="email" [tabIndex]="1" (keydown.enter)="login()">
        </div>
        <div style="padding: 10px 30px">
          <span class="label">Senha:</span>
          <div class="relative w-full ">
            <input
              type="password"
              id="password"
              formControlName="password"
              class="input-line-bottom mt-1 block w-full px-3 py-2 text-sm shadow-sm placeholder-slate-400 text-skin-color-base bg-skin-input"
              [tabIndex]="2"
              (keydown.enter)="login()"

            />
            <button
              type="button"
              (click)="showPassword('password')"
              class="absolute inset-y-0 right-0 px-3 flex items-center text-gray-600">
              <fa-icon [icon]="faEye"></fa-icon>
            </button>
          </div>

        </div>
        <div style="padding: 10px 30px" class="flex justify-center items-center">
          <button class="btn-warning w-full sm:w-48" [disabled]="this.form.invalid" (click)="login()" [tabIndex]="3">Entrar</button>
        </div>
        <div style="padding: 10px 30px" class="flex justify-center items-center">
          <button class="text-slate-700 dark:text-slate-400">Esqueci minha senha</button>
        </div>
      </div>
    </div>
  </div>
</div>
