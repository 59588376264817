import {HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import {catchError, map, throwError} from "rxjs";
import {Router} from "@angular/router";
import {inject} from "@angular/core";
import {CryptoService} from "../services/crypto.service";
import {ToastrService} from "ngx-toastr";
import {ToasterService} from "../services/error.service";

export const tokenInterceptor: HttpInterceptorFn = (request: HttpRequest<any>, next: HttpHandlerFn) => {
  const toastrService: ToastrService = inject(ToastrService);
  const toasterService: ToasterService= inject(ToasterService);
  const cryptoService: CryptoService = inject(CryptoService);
  const router: Router = inject(Router);

  const token: string | null = window.localStorage.getItem('app-token');

  if (token) {
    request = request.clone({
      setHeaders: {
        'Authorization': 'Bearer ' + token,
      }
    });
  }

  return next(request).pipe(
    map((event: any) => {
      if (event?.body?.encrypted) {
        event.body.data = cryptoService.d(event.body.data)

        return event;
      }
      return event;
    }),
    catchError((error: HttpErrorResponse) => {
       if(error.status === 401) router.navigate(['/login']);
       if(error.status === 422) { toasterService.errorArray(error.error.errors); }
       if(error.status === 404) { toastrService.error('Rota não encontrada'); }
      if(![200, 422, 404, 401].includes(error.status)) { toastrService.error(error.error?.mensagem || error.error?.message); }
      return throwError(() => error);
    }));
}
