import {Component, DestroyRef, inject} from '@angular/core';
import {FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators} from "@angular/forms";
import { ButtonDarkModeComponent } from "../smart-components/button-dark-mode/button-dark-mode.component";
import {AuthService} from "../core/services/auth.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {Router} from "@angular/router";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-login',
  standalone: true,
    imports: [
        FormsModule,
        ButtonDarkModeComponent,
        ReactiveFormsModule,
        FaIconComponent
    ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  /** Services  */
  public authService: AuthService = inject(AuthService);
  public formBuilder: FormBuilder = inject(FormBuilder);
  public destroyRef = inject(DestroyRef)
  public router: Router = inject(Router)
  /** Variaveis  */


  protected form: UntypedFormGroup

  constructor() {
    this.createForm();
  }

  public createForm(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    })
  }

  public login(): void {
    this.authService.login(this.form.value).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (res) => {
        setTimeout(() => this.router.navigate(['/dashboard']),600);
      }
    });
  }

  protected showPassword(input:string): void {
    const inputHtml = document.getElementById(input);

    if (inputHtml.getAttribute('type') === 'password') {
      inputHtml.setAttribute('type', 'text');
    } else {
      inputHtml.setAttribute('type', 'password');
    }
  }

  protected readonly faEye = faEye;
}
